@import '../../../base/assets/scss/_base/config';

.m-channel-subheader {
  &__title, &__2024-label{
    text-align: left;
    font-size: 32px;
    line-height: 24px;
    letter-spacing: -1px;
    color: $batcave;
    text-transform: capitalize;
    padding: 0px 15px;
    font-family: var(--font-family-heading);

    &.cell {
      padding: 0;
    }
    &--static {
      text-transform: none;
    }
  }

  &__2024-label {
    padding-left: 0px;
    font-size: 28px;
    color: $picasso;
    font-weight: 800;
  }
  &__24-label {
    color: $red;
  }

  @media screen and (max-width: 480px) {
    &__title {
      font-size: 28px;
    }
  }
}

.games-main-header {
  display: block;
  text-align: left;

  &__link {
    display: block;
    text-decoration: none;
    text-align: left;
    font-family: var(--font-family-heading);
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: -0.5px;
    color: $dolphin;
  }

}

.games-sub-header {
  text-align: left;
  font-family: var(--font-family-heading);
  font-size: 36px;
  line-height: 24px;
  letter-spacing: -1px;
  color: $batcave;
}
